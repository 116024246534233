<template>
  <component
    :is="component"
    v-bind="$attrs"
  />
</template>

<script>
import { mapState } from 'vuex';
import NetsuiteSettings from '@/components/Integrations/Modules/Netsuite/ImportSettings.vue';
import { integrationTypes } from '@/config/integrations';

export default {
  name: 'Settings',
  components: {
    NetsuiteSettings
  },
  data() {
    return {
      componentByIntegration: {
        [integrationTypes.NETSUITE]: 'NetsuiteSettings'
      }
    };
  },
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration
    }),
    component() {
      return this.componentByIntegration[this.activeIntegration?.type];
    }
  }
};
</script>